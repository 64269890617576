.chapters-search-form {
  display: flex;
  flex-direction: column;
}

.chapters-search-form select,
.chapters-search-form input {
  height: 35px;
  padding-left: 10px;
  font-size: 0.8em;
}

.chapters-search-form select {
  /* Removes the default <select> styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 2px;

  /* Positions background arrow image */
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right 6px center;
}

.chapters-search-form .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.chapters-search-form .form-group label {
  margin-bottom: 3px;
  font-size: 0.8em;
}
