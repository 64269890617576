.chapters-table {
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.chapters-table thead tr {
  background-color: black;
  color: #ffffff;
  text-align: left;
}

.chapters-table th,
.chapters-table td {
  padding: 12px 15px;
}

.chapters-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.chapters-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.chapters-table tbody tr:last-of-type {
  border-bottom: 2px solid black;
}

.chapters-table a {
  text-decoration: underline;
  color: #666;
}
