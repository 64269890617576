.chapters-app {
  width: 55%;
  margin: auto;
}
.chapters-app {
  font-family: sans-serif;
}

@media only screen and (max-width: 600px) {
  .chapters-app {
    width: 100%;
  }
}
